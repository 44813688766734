@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

* html {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gradient-bg-welcome {
    background-color: #0f0e13;
    background-image:
        radial-gradient(at 0% 0%, hsla(253, 16%, 7%, 1) 0, transparent 50%),
        radial-gradient(at 50% 0%, hsla(225, 39%, 30%, 1) 0, transparent 50%),
        radial-gradient(at 100% 0%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
}

.gradient-bg-services {
    background-color: #0f0e13;
    background-image:
        radial-gradient(at 0% 0%, hsla(253, 16%, 7%, 1) 0, transparent 50%),
        radial-gradient(at 50% 100%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
}

.gradient-bg-transactions {
    background-color: #0f0e13;
    background-image:
        radial-gradient(at 0% 100%, hsla(253, 16%, 7%, 1) 0, transparent 50%),
        radial-gradient(at 50% 0%, hsla(225, 39%, 25%, 1) 0, transparent 50%);
}

.gradient-bg-footer {
    background-color: #0f0e13;
    background-image:
        radial-gradient(at 0% 100%, hsla(253, 16%, 7%, 1) 0, transparent 53%),
        radial-gradient(at 50% 150%, hsla(339, 49%, 30%, 1) 0, transparent 50%);
}

.blue-glassmorphism {
    background: rgb(39, 51, 89, 0.4);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
}

/* white glassmorphism */
.white-glassmorphism {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.eth-card {
    background-color: #a099ff;
    background-image:
        radial-gradient(at 83% 67%, rgb(152, 231, 156) 0, transparent 58%),
        radial-gradient(at 67% 20%, hsla(357, 94%, 71%, 1) 0, transparent 59%),
        radial-gradient(at 88% 35%, hsla(222, 81%, 65%, 1) 0, transparent 50%),
        radial-gradient(at 31% 91%, hsla(9, 61%, 61%, 1) 0, transparent 52%),
        radial-gradient(at 27% 71%, hsla(336, 91%, 65%, 1) 0, transparent 49%),
        radial-gradient(at 74% 89%, hsla(30, 98%, 65%, 1) 0, transparent 51%),
        radial-gradient(at 53% 75%, hsla(174, 94%, 68%, 1) 0, transparent 45%);
}

.text-gradient {
    background-color: #fff;
    background-image: radial-gradient(at 4% 36%, hsla(0, 0%, 100%, 1) 0, transparent 53%), radial-gradient(at 100% 60%, rgb(0, 0, 0) 0, transparent 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@tailwind base;
@tailwind components;
@tailwind utilities;